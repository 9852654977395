import { DateTime } from 'luxon';
import GenericDataType from './GenericDataType';

export interface AppointmentEngineer {
  id: string;
  firstName?: string;
  lastName?: string;
}

export default interface Appointment extends GenericDataType {
  address?: Address;
  abortDetails?: AbortDetails;
  atRisk: boolean;
  status: AppointmentStatus;
  category: AppointmentCategory;
  notes: Note[];
  slot: AppointmentSlot;
  engineers: AppointmentEngineer[];
  // customer booking specific
  jobType?: string;
  patch?: string;
  appointmentType?: AppointmentType;
  complaints: Complaint[];
  brand?: Brand;
  customerDetails?: ContactDetails;
  contactDetails?: ContactDetails;
  location: { lat: number; long: number };
  duration: number;
  accountNumber: string | null;
  bookingReference: string;
  dataOptIn: string | null;
  id: string;
  nonce: string | null;
  reason: string | null;
}

export interface AppointmentResponse extends Omit<Appointment, 'engineers'> {
  engineers: string[];
}

export interface Address {
  addressLineOne: string;
  addressLineTwo: string | null;
  addressLineThree: string | null;
  city: string;
  postcode: string;
}

export interface AppointmentType {
  fuelType?: string | null;
  paymentType?: string;
  details:
    | CustomerAppointmentDetails
    | MaintenanceDetails
    | EVAppointmentDetails;
  type: CustomerAppointmentType;
}

export interface MeterTechnicalDetails {
  metersToBeRemoved: ElectricMeterTechnicalDetail[];
  xvali: {
    importMPAN?: string;
    exportMPAN?: string;
    secondaryImportMPAN?: string;
    mopId?: string;
    importMPRN?: string;
    mamId?: string;
    commsHubType?: string;
    signalStrength?: string;
    auxiliaryEquipmentRequired?: string;
    cspRegion?: string;
  };
  oldGasMsn: string;
  oldElecMsn: string;
}

interface MeterTechnicalDetailType {
  type: MeterType;
}

export interface ElectricMeterTechnicalDetail extends MeterTechnicalDetailType {
  mpan: string | null;
  serialNumber: string;
}

export interface GasMeterTechnicalDetail extends MeterTechnicalDetailType {
  serialNumber: string;
}

export interface CustomerAppointmentDetails {
  ihdRequested: boolean;
  mprn: string;
  mpan: string;
  secondaryMpan?: string | null;
  hasConcealedMeter: boolean;
  meterTechnicalDetails?: MeterTechnicalDetails;
  elecTariff?: string;
}

export interface EVAppointmentDetails {
  mpan: string;
  channel: EVFormChannel;
  zohoAccountId?: string;
  jumptechProjectId?: string;
}

export interface MaintenanceDetails {
  isEmergency: boolean;
  mprn: string | null;
  mpan: string | null;
  jobCode: string | null;
  jobDescription: string | null;
  oldElecMsn: string | null;
  oldSsc: string | null;
  oldCtRatio: string | null;
  oldElecMeterLocation: string | null;
  oldMeterType: string | null;
  oldGasMsn: string | null;
  oldGasMeterLocation: string | null;
  newSsc: string | null;
  storageUnderfloorHeating: string | null;
  newMeterType: string | null;
  newPaymentType: string | null;
  meterMode: string | null;
  energisationStatus: string | null;
  meterPositioning: string | null;
  pressureTier: string | null;
  elecTariff: string | null;
}

export interface ContactDetails {
  title: string;
  firstName: string;
  lastName: string;
  passphrase?: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber?: string;
  emailAddress?: string;
  vulnerability?: CustomerVulnerability;
}

export interface CustomerVulnerability {
  isOver65: boolean;
  hasDisability: boolean;
  hasHearingDifficulties: boolean;
  hasSpeechDifficulties: boolean;
  hasSmellDifficulties: boolean;
  isBlindOrPartiallySighted: boolean;
  hasSeriousIllness: boolean;
  speaksOtherLanguage: boolean;
  isReliantOnMedicalEquipment: boolean;
  hasYoungChildren: boolean;
  other: string;
}

export interface AbortDetails {
  category: string;
  reason: string;
  comments: string | null;
  rebook: boolean | null;
}

export interface AppointmentAbortDetails {
  appointmentId: string;
  appointmentDate: string;
  abortDetails: AbortDetails;
}

export interface Site {
  mpan: string | null;
  mprn: string | null;
}

export interface SiteAbortDetails {
  site: Site;
  aborts: AppointmentAbortDetails[];
}

export enum BookingChannel {
  AEGIS = 'AEGIS'
}

export enum CustomerAppointmentType {
  SMETS1 = 'SMETS1',
  SMETS2 = 'SMETS2',
  MAINTENANCE = 'MAINTENANCE',
  EV = 'EV'
}

export enum Brand {
  OVO = 'OVO',
  BOOST = 'BOOST'
}

// This is the way appointment statuses come from the API
export enum AppointmentStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  ON_ROUTE = 'ON_ROUTE',
  ARRIVED = 'ARRIVED',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  ABORTED = 'ABORTED'
}

// This is the way we show appointment statuses in the UI
export enum UIAppointmentStatus {
  CANCELLED = 'Cancelled',
  BOOKED = 'Booked',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
  EN_ROUTE = 'Еn route'
}

export enum EVFormChannel {
  OVO = 'OVO',
  FLEET = 'Fleet',
  OHME = 'Ohme',
  HYPERVOLT = 'HyperVolt'
}

export enum AppointmentAutocompleteStatus {
  CANCELLED = 'Cancelled',
  PENDING = 'Booked',
  ACTIVE = 'In progress',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
  ON_ROUTE = 'Еn route'
}

export interface Complaint {
  accountNumber: string;
  severity: number | null;
  category: string | null;
  subject: string | null;
  description: string | null;
  origin: string | null;
  createdDate: string;
}

export interface Note {
  type: NoteType;
  note: string;
}

export interface AppointmentSlot {
  date: string;
  startTime: string;
  endTime: string;
}

export enum AppointmentCategory {
  CUSTOMER = 'CUSTOMER',
  MEETING = 'MEETING'
}

export enum NoteType {
  INSTALLER = 'INSTALLER',
  SCHEDULER = 'SCHEDULER',
  ADMIN = 'ADMIN'
}

export enum AppointmentReason {
  MEETING = 'Meeting',
  DRUG_ALCOHOL_TEST = 'Drug & Alcohol Test',
  TRAINING = 'Training',
  TRAVEL = 'Travel',
  LUNCH = 'Lunch'
}

export enum FuelType {
  DUAL = 'DUAL',
  ELEC = 'ELEC',
  GAS = 'GAS'
}

export enum MeterType {
  ELEC = 'ELEC',
  GAS = 'GAS'
}

export enum PaymentType {
  PAYG = 'PAYG',
  PAYM = 'PAYM'
}

interface CreateCustomerAppointmentRequestCommonFields {
  category: AppointmentCategory.CUSTOMER;
  jobType: string;
  appointmentType: object;
  bookingChannel?: BookingChannel.AEGIS;
  address: {
    addressLineOne: string;
    postcode: string;
    city: string;
    addressLineTwo: string | null;
    addressLineThree: string | null;
  };
  customerDetails: {
    title?: string;
    firstName: string;
    lastName: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string | null;
    vulnerability: CustomerVulnerability | {};
  };
  slot: {
    startTime: string;
    endTime: string;
    date: string;
  };
  notes: {
    note: string;
    type: NoteType;
  }[];
}
export interface CreateCustomerAppointmentRequestSmart
  extends CreateCustomerAppointmentRequestCommonFields {
  brand: Brand;
  appointmentType: {
    type: CustomerAppointmentType;
    fuelType: FuelType;
    paymentType: PaymentType;
    details: {
      mprn: string;
      mpan: string;
      meterTechnicalDetails: {
        oldGasMsn: string;
        oldElecMsn: string;
      };
    };
  };
  accountNumber: string;
}

export interface CreateCustomerAppointmentRequestEV
  extends CreateCustomerAppointmentRequestCommonFields {
  appointmentType: {
    type: CustomerAppointmentType;
    details: {
      channel: EVFormChannel;
      jumptechProjectId?: string;
      zohoAccountId?: string;
      mpan?: string;
    };
  };
}

export interface CreateJobFormValues {
  appointmentType?: string;
  fuelType?: FuelType;
  date: DateTime;
  timeSlot: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  postcode: string;
  mpan: string;
  mprn: string;
  electricityMsn: string;
  gasMsn: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  accountNumber: string;
  zohoAccountId?: string;
  channel?: EVFormChannel;
  jumptechURL?: string;
  secondaryNumber?: string;
  brand: Brand;
  paymentType: PaymentType;
  bookingNotes?: string;
  jobType: {
    id: string;
    name: string;
  };
  customerHasVulnerabilities?: string;
}

export interface AbortCustomerAppointmentRequest {
  categoryId: string;
  reasonId: string;
  rebook: boolean;
  comments?: string;
}

export interface AppointmentCanCancelResponse {
  canBeCancelled: boolean;
  canBeCancelledUntil: string;
  canBeAborted: boolean;
}
