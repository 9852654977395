/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formRef"] }] */

import { makeStyles, IconButton } from '@material-ui/core';
import Button from 'components/Button';
import { renderErrorOrLoading } from 'components/Crud';
import {
  ConditionalField,
  Field,
  Form,
  FormValues,
  patchDataToSelect,
  Radio,
  yupSchemaToValidationFunc
} from 'components/Forms';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormApiWithRestart } from 'components/Forms/Form';
import TimeTableSelect from 'components/Forms/TimetableSelect';
import Modal, { useModalStyles } from 'components/Modal';
import Typography from 'components/Typography';
import ErrorDisplay from 'components/ErrorDisplay';
import { DateTime, Interval } from 'luxon';
import Adjustment, {
  AdjustmentPriority,
  AdjustmentType,
  AdjustmentChangeType,
  DayOfWeek,
  Pattern
} from 'models/Adjustment';
import AdjustmentGroup from 'models/AdjustmentGroup';
import React, { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAdjustmentsState, usePatchesState } from 'store/selectors';
import {
  adjustmentTypeFilter,
  getAdjustmentForDay,
  getAdjustmentGroup
} from 'utils/adjustments';
import { dateToDayOfWeek, getFirstWeekAsDateArray } from 'utils/dates';
import { mergeErrors } from 'utils/utils';
import { EngineerCalendarContext } from 'views/Engineers/EngineerCalendar/EngineerCalendar';
import { adjustmentGroupsSlice } from 'views/Engineers/store';
import { FormSpy } from 'react-final-form';
import { TemporaryAdjustmentConstants } from 'views/Engineers/utils';
import {
  AdjustmentFormButtonActions,
  TemporaryAdjustmentFormContext,
  TemporaryAdjustmentFormModalProps
} from '../AdjustmentFormModals';
import { AdjustmentFormCommonFieldName } from './adjustmentFormCommonConfig';
import { useAdjustmentFormStyles } from './FormStyles';
import {
  getDayFieldName,
  patchFormCommonFields,
  patchFormInitialValues,
  patchFormStartLocationFields,
  patchFormValidationSchema,
  patchFormWeekdayFields,
  PostcodeChangeFieldName
} from './patchChangeFormConfig';
import { formSpyRelevantDays, useFormDaysOfWeek } from './useFormDaysOfWeek';

const useStyles = makeStyles(
  {
    previousButton: {
      marginRight: 'auto'
    }
  },
  { name: 'TemporaryPatchChangeModal' }
);

function TemporaryPatchFormModalButtons({
  onPrevious,
  onCancel,
  onSubmit,
  showPrevious
}: AdjustmentFormButtonActions) {
  const classes = useStyles();

  return (
    <>
      {showPrevious ? (
        <Button
          key="patchChangeModalPreviousButton"
          color="secondary"
          size="small"
          onClick={onPrevious}
          data-testid="patchChangeModalPreviousButton"
          className={classes.previousButton}
        >
          Previous
        </Button>
      ) : null}

      <Button
        key="patchChangeModalCancelButton"
        color="secondary"
        size="small"
        onClick={onCancel}
        data-testid="patchChangeModalCancelButton"
      >
        Cancel
      </Button>

      <Button
        key="patchChangeModalSubmitButton"
        color="primary"
        size="small"
        onClick={onSubmit}
        data-testid="patchChangeModalSubmitButton"
      >
        Save
      </Button>
    </>
  );
}

function getGeneratedAdjustments(
  formValues: FormValues,
  adjustmentGroupId?: string,
  adjustmentGroup?: AdjustmentGroup
) {
  const startDate = DateTime.fromISO(
    formValues[AdjustmentFormCommonFieldName.ValidFrom] as string
  );
  const endDate = DateTime.fromISO(
    formValues[AdjustmentFormCommonFieldName.ValidTo] as string
  );

  const dates = getFirstWeekAsDateArray(
    Interval.fromDateTimes(startDate, endDate)
  );

  const engineerMobilisationStartLocationChange =
    formValues[PostcodeChangeFieldName.AddStartLocation] === 'true'
      ? [
          {
            field: AdjustmentType.Postcode,
            change: formValues[PostcodeChangeFieldName.Postcode]
          }
        ]
      : [];

  return dates.reduce((acc: Adjustment[], date) => {
    const day = dateToDayOfWeek(date);
    const adjustmentForDay = getAdjustmentForDay(
      day,
      adjustmentGroup?.adjustments ?? []
    );

    const fieldValueFormat = date.weekdayLong;

    const currentValue = formValues[getDayFieldName(fieldValueFormat)];

    if (currentValue !== TemporaryAdjustmentConstants.Empty) {
      const adjustmentObj: Adjustment = {
        ...((adjustmentForDay ? { id: adjustmentForDay.id } : {}) as {
          id: string;
        }),
        ...(adjustmentGroupId ? { groupId: adjustmentGroupId } : {}),
        reason: formValues[AdjustmentFormCommonFieldName.Reason] as string,
        startDate: DateTime.fromISO(
          formValues[AdjustmentFormCommonFieldName.ValidFrom] as string
        ).toISODate(),
        endDate: DateTime.fromISO(
          formValues[AdjustmentFormCommonFieldName.ValidTo] as string
        ).toISODate(),
        pattern: {
          type: Pattern.Weekly,
          weekdays: [day.toUpperCase() as DayOfWeek]
        },
        changes: [
          {
            field: AdjustmentType.Patch,
            change: formValues[getDayFieldName(date.weekdayLong)] as string
          },
          ...engineerMobilisationStartLocationChange
        ] as AdjustmentChangeType[],
        priority: AdjustmentPriority.Temporary
      };

      return [...acc, adjustmentObj];
    }

    return acc;
  }, [] as Adjustment[]);
}

export default function TemporaryPatchAdjustmentModal({
  onPrevious,
  onDelete
}: TemporaryAdjustmentFormModalProps) {
  const [formDates, setValueFormSpy, formDaysOfWeek] = useFormDaysOfWeek();

  const { engineerData } = useContext(EngineerCalendarContext);
  const { adjustment, isOpen, setIsOpen } = useContext(
    TemporaryAdjustmentFormContext
  );

  const dispatch = useDispatch();
  const classes = useAdjustmentFormStyles();
  const modalClasses = useModalStyles();
  const patchesState = usePatchesState();

  const formRef = useRef<FormApiWithRestart>();

  const handleSubmitButtonClick = () => {
    formRef.current?.submit();
  };

  const handleClose = () => {
    setIsOpen?.(false);
  };

  const adjustmentsState = useAdjustmentsState();
  const patchAdjustments = adjustmentsState.items.filter(
    adjustmentTypeFilter(AdjustmentType.Patch)
  );

  const adjustmentGroup = adjustment?.groupId
    ? getAdjustmentGroup(patchAdjustments, adjustment.groupId)
    : undefined;

  const patchFormSubmitHandler = (formValues: FormValues) => {
    const adjustmentGroupId = adjustment?.groupId;
    const adjustmentGroupToSend = {
      adjustments: getGeneratedAdjustments(
        formValues,
        adjustmentGroupId,
        adjustmentGroup
      )
    };

    if (adjustment == undefined) {
      dispatch(
        adjustmentGroupsSlice.actions.add(
          adjustmentGroupToSend,
          engineerData?.id
        )
      );
    } else {
      dispatch(
        adjustmentGroupsSlice.actions.edit(
          adjustmentGroupToSend,
          adjustment.groupId,
          engineerData?.id
        )
      );
    }

    handleClose();
  };

  const [
    patchAdjustmentRadioField,
    postcodeField
  ] = patchFormStartLocationFields;
  return (
    renderErrorOrLoading(
      patchesState.isLoading,
      'editEngineerSpinner',
      mergeErrors([patchesState.error])
    ) || (
      <Modal
        title="Patch Change"
        testId="patchAdjustmentFormModal"
        open={isOpen || false}
        onClose={handleClose}
        modalActions={
          <TemporaryPatchFormModalButtons
            onCancel={handleClose}
            onSubmit={handleSubmitButtonClick}
            onPrevious={onPrevious}
            showPrevious
          />
        }
        additionalButtons={
          adjustment ? (
            <IconButton
              aria-label="delete"
              onClick={onDelete}
              className={modalClasses.button}
              data-testid="deletePatchAdjustmentIconButton"
            >
              <DeleteIcon />
            </IconButton>
          ) : null
        }
      >
        <Form
          initialValues={patchFormInitialValues(adjustmentGroup)}
          validationFunc={yupSchemaToValidationFunc(patchFormValidationSchema)}
          onSubmit={patchFormSubmitHandler}
          goBackToPreviousPage={false}
        >
          {({ form }) => {
            formRef.current = form;
            const formState = form.getState();
            return (
              <>
                {patchFormCommonFields.map(field => (
                  <Field key={field.id} field={field} />
                ))}
                <Typography variant="h2" className={classes.typography}>
                  New Patches
                </Typography>
                {formState.submitFailed &&
                formState.errors?.atLeastOneFilled ? (
                  <div className={classes.allEmptyErrorContainer}>
                    <ErrorDisplay
                      status={{
                        message: formState.errors?.atLeastOneFilled
                      }}
                    />
                  </div>
                ) : null}
                <div data-testid="patchWeekdaySelectsWrapper">
                  {formDaysOfWeek.map(day => (
                    <div
                      className={classes.weekdaysWrapper}
                      key={`timetableWrapper-${day}`}
                    >
                      <Typography
                        data-testid={`patchAdjustmentSelector${day}`}
                        key={day}
                        component="label"
                        htmlFor={`patchChange${day}`}
                      >
                        {day}
                      </Typography>
                      <div className={classes.weekdaySelectWrapper}>
                        <TimeTableSelect
                          field={{
                            ...patchFormWeekdayFields[day],
                            className: classes.weekdaySelect,
                            options: [
                              {
                                value: TemporaryAdjustmentConstants.Empty,
                                label: ''
                              },
                              ...patchesState.items?.map(patchDataToSelect)
                            ]
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <FormSpy
                  subscription={{
                    values: true
                  }}
                >
                  {({ values }) => {
                    return formSpyRelevantDays(
                      values,
                      formDates,
                      setValueFormSpy
                    );
                  }}
                </FormSpy>
                <>
                  <Radio field={patchAdjustmentRadioField} />
                  <ConditionalField
                    when={patchAdjustmentRadioField.name}
                    is="true"
                  >
                    <Field field={postcodeField} />
                  </ConditionalField>
                </>
              </>
            );
          }}
        </Form>
      </Modal>
    )
  );
}
