import React, { useEffect } from 'react';
import { EditView, renderErrorOrLoading } from 'components/Crud';
import useStyles from 'components/Crud/styles';
import { appointmentDomain } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { mergeErrors } from 'utils/utils';
import { mapRequestDataToField } from 'components/Forms/utils/dataMap';
import Appointment from 'models/Appointment';
import {
  validationSchema,
  appointmentFormFields,
  processFormFields
} from './formConfig';
import { engineerSlice, EngineersSelector } from './store';

function EditAppointmentView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const processAppointmentData = mapRequestDataToField({
    engineer: (appointment: Appointment) => appointment.engineers,
    date: (appointment: Appointment) => appointment.slot.date,
    startTime: ({ slot: { date, startTime } }: Appointment) =>
      `${date}T${startTime}`,
    endTime: ({ slot: { date, endTime } }: Appointment) => `${date}T${endTime}`,
    reason: (appointment: Appointment) => appointment.reason,
    postcode: (appointment: Appointment) => appointment.address?.postcode,
    notes: (appointment: Appointment) =>
      appointment.notes
        .reduce((acc, { note }) => [...acc, note], [] as string[])
        .join('\n')
  });

  const engineerSelector = useSelector<RootState, EngineersSelector>(
    engineerSlice.selector
  );

  // TODO - temporary high page size values until FFT-612 is completed
  useEffect(() => {
    if (engineerSlice.actions.changePagination) {
      dispatch(
        engineerSlice.actions.changePagination({
          pageIndex: 0,
          pageSize: 10000
        })
      );
    }
  }, []);
  return (
    renderErrorOrLoading(
      engineerSelector.isLoading,
      'editEngineerSpinner',
      mergeErrors([engineerSelector.error]),
      classes
    ) || (
      <EditView
        domain={appointmentDomain}
        fields={appointmentFormFields('edit', engineerSelector.items)}
        validationSchema={validationSchema}
        presubmitCallback={processFormFields}
        processDomainData={processAppointmentData}
      />
    )
  );
}

export default EditAppointmentView;
