import React, { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { getDomainSlice, RootState } from 'store';
import Snackbars from 'components/Alerts';

import { DomainItem } from 'models/Domain';
import Typography from 'components/Typography';
import {
  CrudViewProps,
  formSubmitHandler,
  getInitialFieldValues
} from '../utils';
import useStyles from '../styles';
import {
  FormValues,
  yupSchemaToValidationFunc,
  Form,
  Buttons
} from '../../Forms';

const EditView = ({
  domain,
  fields,
  title = `Edit ${domain.type}`,
  validationSchema,
  presubmitCallback,
  processDomainData
}: CrudViewProps) => {
  const classes = useStyles();
  const resetButtonState = useRef<boolean | NodeJS.Timeout>(false);
  const { id: domainItemId } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const { actions, selector } = getDomainSlice(domain);

  // Clear button state timeout on unmount
  useEffect(() => {
    // TODO: FIX (FT-1960) - The way this is set up is causing this request to get triggered multiple times
    if (actions.get.read && domainItemId) {
      dispatch(actions.get.read(domainItemId));
    }
    return () => {
      clearTimeout(resetButtonState.current as NodeJS.Timeout);
    };
  }, []);

  const { items: domainItems } = useSelector<
    RootState,
    ReturnType<typeof selector>
  >(selector);

  // TODO: Handle scenario when `data` is undefined.
  // At the moment this will just show an empty edit form if `data` is undefined.
  // This also needs tests to cover this scenario.
  const data = domainItems.find((item: DomainItem) => item.id === domainItemId);

  if (fields == null) {
    return null;
  }

  const validationFunc = yupSchemaToValidationFunc(validationSchema);
  const apiCallback = (formValues: FormValues) =>
    dispatch(actions.edit(formValues, domainItemId));

  return (
    <>
      <Grid container className={classes.formContainer}>
        <Grid item xs={12}>
          <Typography variant="h1" data-qa-id="createDomainPageTitle">
            {title}
          </Typography>
          <Paper className={`${classes.root} ${classes.paper}`}>
            <Form
              validationFunc={validationFunc}
              fields={fields}
              onSubmit={formSubmitHandler(apiCallback, presubmitCallback)}
              initialValues={getInitialFieldValues(
                fields,
                data,
                processDomainData
              )}
              autoReset={false}
            >
              {({ submitting, submitSucceeded, submitErrors }) => (
                <Buttons
                  isSubmitting={submitting}
                  success={!submitErrors && submitSucceeded}
                />
              )}
            </Form>
          </Paper>
        </Grid>
      </Grid>
      <Snackbars />
    </>
  );
};

export default EditView;
