import * as Yup from 'yup';

import {
  FieldInputValue,
  InputFieldProps,
  patchDataToSelect
} from 'components/Forms';
import {
  Pattern,
  DayOfWeek,
  ScheduleAdjustmentReason,
  PatchAdjustmentReason,
  EfficiencyAdjustmentReason
} from 'models/Adjustment';
import { capitalise } from 'utils';
import Patch from 'models/Patches';
import { getEfficiencyOptions } from 'views/Engineers/utils';

function recurringSelectOptions(): FieldInputValue[] {
  return [
    {
      value: Pattern.Daily,
      label: 'Daily',
      key: 'daily'
    },
    {
      value: Pattern.Weekly,
      label: 'Weekly',
      key: 'weekly'
    }
  ];
}

export const scheduleReasonSelectOptions = (): FieldInputValue[] => {
  return [
    {
      value: ScheduleAdjustmentReason.AnnualLeave,
      label: ScheduleAdjustmentReason.AnnualLeave,
      key: 'annualLeave'
    },
    {
      value: ScheduleAdjustmentReason.CompassionateLeave,
      label: ScheduleAdjustmentReason.CompassionateLeave,
      key: 'compassionateLeave'
    },
    {
      value: ScheduleAdjustmentReason['EV/Solar'],
      label: ScheduleAdjustmentReason['EV/Solar'],
      key: 'ev/solar'
    },
    {
      value: ScheduleAdjustmentReason.FlexibleWorking,
      label: ScheduleAdjustmentReason.FlexibleWorking,
      key: 'flexibleWorking'
    },
    {
      value: ScheduleAdjustmentReason.Mentoring,
      label: ScheduleAdjustmentReason.Mentoring,
      key: 'mentoring'
    },
    {
      value: ScheduleAdjustmentReason.Mobilisation,
      label: ScheduleAdjustmentReason.Mobilisation,
      key: 'mobilisation'
    },
    {
      value: ScheduleAdjustmentReason.MomentsThatMatter,
      label: ScheduleAdjustmentReason.MomentsThatMatter,
      key: 'momentsThatMatter'
    },
    {
      value: ScheduleAdjustmentReason.OffTheTools,
      label: ScheduleAdjustmentReason.OffTheTools,
      key: 'offTheTools'
    },
    {
      value: ScheduleAdjustmentReason.Overtime,
      label: ScheduleAdjustmentReason.Overtime,
      key: 'overtime'
    },
    {
      value: ScheduleAdjustmentReason.PartDayAnnualLeave,
      label: ScheduleAdjustmentReason.PartDayAnnualLeave,
      key: 'partDayAnnualLeave'
    },
    {
      value: ScheduleAdjustmentReason.PhasedReturn,
      label: ScheduleAdjustmentReason.PhasedReturn,
      key: 'phasedReturn'
    },
    {
      value: ScheduleAdjustmentReason.Sickness,
      label: ScheduleAdjustmentReason.Sickness,
      key: 'sickness'
    },
    {
      value: ScheduleAdjustmentReason.SelfIsolating,
      label: ScheduleAdjustmentReason.SelfIsolating,
      key: 'selfIsolating'
    },
    {
      value: ScheduleAdjustmentReason.Suspended,
      label: ScheduleAdjustmentReason.Suspended,
      key: 'suspended'
    },
    {
      value: ScheduleAdjustmentReason.Training,
      label: ScheduleAdjustmentReason.Training,
      key: 'training'
    }
  ];
};

export const scheduleReasonSelectOptionsOldValues = (): FieldInputValue[] => {
  return [
    {
      value: ScheduleAdjustmentReason.AnnualLeave,
      label: ScheduleAdjustmentReason.AnnualLeave,
      key: 'annualLeave'
    },
    {
      value: ScheduleAdjustmentReason.NewStarter,
      label: ScheduleAdjustmentReason.NewStarter,
      key: 'newStarter'
    },
    {
      value: ScheduleAdjustmentReason.BankHoliday,
      label: ScheduleAdjustmentReason.BankHoliday,
      key: 'bankHoliday'
    },
    {
      value: ScheduleAdjustmentReason.SickLeave,
      label: ScheduleAdjustmentReason.SickLeave,
      key: 'sickLeave'
    },
    {
      value: ScheduleAdjustmentReason.Furlough,
      label: ScheduleAdjustmentReason.Furlough,
      key: 'furlough'
    },
    {
      value: ScheduleAdjustmentReason.SelfIsolating,
      label: ScheduleAdjustmentReason.SelfIsolating,
      key: 'selfIsolating'
    },
    {
      value: ScheduleAdjustmentReason.Mentoring,
      label: ScheduleAdjustmentReason.Mentoring,
      key: 'mentoring'
    },
    {
      value: ScheduleAdjustmentReason.Apprentice,
      label: ScheduleAdjustmentReason.Apprentice,
      key: 'apprentice'
    },
    {
      value: ScheduleAdjustmentReason.ParentalLeave,
      label: ScheduleAdjustmentReason.ParentalLeave,
      key: 'parentalLeave'
    },
    {
      value: ScheduleAdjustmentReason.SaturdayWorking,
      label: ScheduleAdjustmentReason.SaturdayWorking,
      key: 'saturdayWorking'
    },
    {
      value: ScheduleAdjustmentReason.Suspended,
      label: ScheduleAdjustmentReason.Suspended,
      key: 'suspended'
    },
    {
      value: ScheduleAdjustmentReason.FlexiDayOff,
      label: ScheduleAdjustmentReason.FlexiDayOff,
      key: 'flexiDayOff'
    },
    {
      value: ScheduleAdjustmentReason.Leaver,
      label: ScheduleAdjustmentReason.Leaver,
      key: 'leaver'
    },
    {
      value: ScheduleAdjustmentReason.Misc,
      label: ScheduleAdjustmentReason.Misc,
      key: 'misc'
    },
    {
      value: ScheduleAdjustmentReason.Ringfenced,
      label: ScheduleAdjustmentReason.Ringfenced,
      key: 'ringfenced'
    },
    {
      value: ScheduleAdjustmentReason.SixthDayWorking,
      label: ScheduleAdjustmentReason.SixthDayWorking,
      key: 'sixthDayWorking'
    }
  ];
};

export const patchReasonOptions = (): FieldInputValue[] => {
  return [
    {
      value: ScheduleAdjustmentReason.EngineerMobilisation,
      label: ScheduleAdjustmentReason.EngineerMobilisation,
      key: 'engineerMobilisation'
    },
    {
      value: ScheduleAdjustmentReason.Mitigation,
      label: ScheduleAdjustmentReason.Mitigation,
      key: 'mitigation'
    },
    {
      value: ScheduleAdjustmentReason.PatchRotation,
      label: ScheduleAdjustmentReason.PatchRotation,
      key: 'patchRotation'
    },
    {
      value: ScheduleAdjustmentReason.SaturdayWorking,
      label: ScheduleAdjustmentReason.SaturdayWorking,
      key: 'saturdayWorking'
    }
  ];
};

export const efficiencyReasonOptions = (): FieldInputValue[] => {
  return [
    {
      value: ScheduleAdjustmentReason.Bybox,
      label: ScheduleAdjustmentReason.Bybox,
      key: 'byBox'
    },
    {
      value: ScheduleAdjustmentReason.CompetencyRemoval,
      label: ScheduleAdjustmentReason.CompetencyRemoval,
      key: 'competencyRemoval'
    },
    {
      value: ScheduleAdjustmentReason.EvCharging,
      label: ScheduleAdjustmentReason.EvCharging,
      key: 'evCharging'
    },
    {
      value: ScheduleAdjustmentReason.FixedAppointmentWorkaround,
      label: ScheduleAdjustmentReason.FixedAppointmentWorkaround,
      key: 'fixedAppointmentWorkaround'
    },
    {
      value: ScheduleAdjustmentReason.Mentoring,
      label: ScheduleAdjustmentReason.Mentoring,
      key: 'mentoring'
    },
    {
      value: ScheduleAdjustmentReason.Mobilisation,
      label: ScheduleAdjustmentReason.Mobilisation,
      key: 'mobilisation'
    },
    {
      value: ScheduleAdjustmentReason.NewStarter,
      label: ScheduleAdjustmentReason.NewStarter,
      key: 'newStarter'
    },
    {
      value: ScheduleAdjustmentReason.ReducedWorkloadForPersonalMedicalReasons,
      label: ScheduleAdjustmentReason.ReducedWorkloadForPersonalMedicalReasons,
      key: 'reducedWorkloadForPersonalMedicalReasons'
    },
    {
      value: ScheduleAdjustmentReason.SaturdayWorking,
      label: ScheduleAdjustmentReason.SaturdayWorking,
      key: 'saturdayWorking'
    }
  ];
};

export const skillsReasonOptions = (): FieldInputValue[] => {
  return [
    {
      value: ScheduleAdjustmentReason.VoidsRC,
      label: ScheduleAdjustmentReason.VoidsRC,
      key: 'VoidsRC'
    },
    {
      value: ScheduleAdjustmentReason.ComplaintsRC,
      label: ScheduleAdjustmentReason.ComplaintsRC,
      key: 'ComplaintsRC'
    },
    {
      value: ScheduleAdjustmentReason.ReservedCapacity,
      label: ScheduleAdjustmentReason.ReservedCapacity,
      key: 'ReservedCapacity'
    },
    {
      value: ScheduleAdjustmentReason.Planning,
      label: ScheduleAdjustmentReason.Planning,
      key: 'Planning'
    },
    {
      value: ScheduleAdjustmentReason.EV,
      label: ScheduleAdjustmentReason.EV,
      key: 'EV'
    },
    {
      value: ScheduleAdjustmentReason.Overtime,
      label: ScheduleAdjustmentReason.Overtime,
      key: 'Overtime'
    },
    {
      value: ScheduleAdjustmentReason.Competency,
      label: ScheduleAdjustmentReason.Competency,
      key: 'Competency'
    },
    {
      value: ScheduleAdjustmentReason.CoverageRotation,
      label: ScheduleAdjustmentReason.CoverageRotation,
      key: 'CoverageRotation'
    }
  ];
};

const patchReasonSelectOptions = (): FieldInputValue[] => {
  return [
    {
      value: PatchAdjustmentReason.PostcodeAdjustment,
      label: PatchAdjustmentReason.PostcodeAdjustment,
      key: 'postcodeAdjustment'
    }
  ];
};

const efficiencyReasonSelectOptions = (): FieldInputValue[] => {
  return [
    {
      value: EfficiencyAdjustmentReason.NewStarter,
      label: EfficiencyAdjustmentReason.NewStarter,
      key: 'newStarter'
    },
    {
      value: EfficiencyAdjustmentReason.Mentoring,
      label: EfficiencyAdjustmentReason.Mentoring,
      key: 'mentoring'
    },
    {
      value: EfficiencyAdjustmentReason.TeamLeader,
      label: EfficiencyAdjustmentReason.TeamLeader,
      key: 'teamLeader'
    }
  ];
};

export const weekdayCheckboxes: InputFieldProps[] = Object.keys(DayOfWeek).map(
  day => {
    const id = `weeklyPattern${capitalise(day)}`;

    return {
      id,
      label: day,
      name: day.toUpperCase(),
      qaId: id,
      inputType: 'checkbox',
      conditional: {
        when: 'pattern',
        is: Pattern.Weekly
      }
    };
  }
);

const reasonOptionsConfig = {
  patch: patchReasonSelectOptions(),
  shift: scheduleReasonSelectOptions(),
  oldShift: scheduleReasonSelectOptionsOldValues(),
  efficiency: efficiencyReasonSelectOptions()
};

export function getSharedAdjustmentFormConfig(
  type: 'shift' | 'oldShift' | 'efficiency' | 'patch'
): {
  descriptiveFields: InputFieldProps[];
  timePeriodFields: InputFieldProps[];
} {
  return {
    descriptiveFields: [
      {
        id: `${type}Reason`,
        label: 'Reason',
        name: 'reason',
        qaId: `${type}AddReason`,
        inputType: 'select',
        options: reasonOptionsConfig[type]
      }
    ],
    timePeriodFields: [
      {
        id: `${type}StartDate`,
        label: 'Valid from',
        name: 'startDate',
        qaId: `${type}AddValidFrom`,
        inputType: 'date'
      },
      {
        id: `${type}EndDate`,
        label: 'Valid to',
        name: 'endDate',
        qaId: `${type}AddValidTo`,
        inputType: 'date'
      }
    ]
  };
}

export function scheduleAdjustmentFormFields(): InputFieldProps[] {
  const { descriptiveFields, timePeriodFields } = getSharedAdjustmentFormConfig(
    'shift'
  );

  return [...descriptiveFields, ...timePeriodFields];
}

export function scheduleAdjustmentFormFieldsOldView(): InputFieldProps[] {
  const { descriptiveFields, timePeriodFields } = getSharedAdjustmentFormConfig(
    'oldShift'
  );

  return [...descriptiveFields, ...timePeriodFields];
}

export const efficiencyAdjustmentFormFields = (): InputFieldProps[] => {
  const { descriptiveFields, timePeriodFields } = getSharedAdjustmentFormConfig(
    'efficiency'
  );

  return [
    ...descriptiveFields,
    {
      id: `efficiencyPattern`,
      label: 'Recurring',
      name: 'pattern',
      qaId: `efficiencyAddRecurring`,
      inputType: 'select',
      options: recurringSelectOptions()
    },
    ...weekdayCheckboxes,
    ...timePeriodFields,
    {
      id: 'efficiency',
      label: 'Efficiency',
      name: 'efficiency',
      qaId: 'addEfficiencyAdjustment',
      inputType: 'select',
      options: getEfficiencyOptions()
    }
  ];
};

const sharedValidationSchema = {
  reason: Yup.string().required('Required'),
  startDate: Yup.string()
    .required('Required')
    .nullable(),
  endDate: Yup.string()
    .required('Required')
    .nullable()
};

const scheduleWeekdayFields: [string, string, string][] = [
  ['startTimeMonday', 'endTimeMonday', 'checkBoxMonday'],
  ['startTimeTuesday', 'endTimeTuesday', 'checkBoxTuesday'],
  ['startTimeWednesday', 'endTimeWednesday', 'checkBoxWednesday'],
  ['startTimeThursday', 'endTimeThursday', 'checkBoxThursday'],
  ['startTimeFriday', 'endTimeFriday', 'checkBoxFriday'],
  ['startTimeSaturday', 'endTimeSaturday', 'checkBoxSaturday'],
  ['startTimeSunday', 'endTimeSunday', 'checkBoxSunday']
];

const scheduleFieldsSchema = scheduleWeekdayFields.reduce<{
  [key: string]:
    | Yup.StringSchema<string | null | undefined>
    | Yup.BooleanSchema;
}>((acc, [startTimeField, endTimeField, checkboxField]: string[]) => {
  return {
    ...acc,
    [startTimeField]: Yup.string().nullable(),
    [endTimeField]: Yup.string().nullable(),
    [checkboxField]: Yup.boolean()
  };
}, {});

export const shiftValidationSchema = Yup.object()
  .shape({
    ...sharedValidationSchema,
    ...scheduleFieldsSchema
  })
  .test(
    'OK',
    'Please fill "Start time" and "End time" or select "Not working" for at least one day of the week',
    formValues => {
      if (!formValues.startDate || !formValues.endDate || !formValues.reason) {
        return true;
      }
      return scheduleWeekdayFields
        .map(
          ([startTimeField, endTimeField, checkboxField]) =>
            (Boolean(formValues[startTimeField]) &&
              Boolean(formValues[endTimeField])) ||
            formValues[checkboxField]
        )
        .some(item => item);
    }
  );

export const efficiencyValidationSchema = Yup.object()
  .shape({
    ...sharedValidationSchema,
    efficiency: Yup.string().required('Required'),
    pattern: Yup.string().required('Required'),
    MONDAY: Yup.boolean(),
    TUESDAY: Yup.boolean(),
    WEDNESDAY: Yup.boolean(),
    THURSDAY: Yup.boolean(),
    FRIDAY: Yup.boolean(),
    SATURDAY: Yup.boolean(),
    SUNDAY: Yup.boolean()
  })
  .test('OK', 'Please select at least one day of the week', formValues => {
    if (formValues.pattern === Pattern.Weekly) {
      return Boolean(
        formValues.MONDAY ||
          formValues.TUESDAY ||
          formValues.WEDNESDAY ||
          formValues.THURSDAY ||
          formValues.FRIDAY ||
          formValues.SATURDAY ||
          formValues.SUNDAY
      );
    }
    return true;
  });

export const patchesValidationSchema = () => {
  const validationSchema = {
    ...sharedValidationSchema,
    patch: Yup.string().required('Required'),
    pattern: Yup.string().required('Required'),
    MONDAY: Yup.boolean(),
    TUESDAY: Yup.boolean(),
    WEDNESDAY: Yup.boolean(),
    THURSDAY: Yup.boolean(),
    FRIDAY: Yup.boolean(),
    SATURDAY: Yup.boolean(),
    SUNDAY: Yup.boolean()
  };

  return Yup.object()
    .shape({
      ...validationSchema,
      differentStartLocation: Yup.string().required('Required'),
      startPostcode: Yup.string().when('differentStartLocation', {
        is: 'true',
        then: Yup.string().required('Required')
      })
    })
    .test('OK', 'Please select at least one day of the week', formValues => {
      if (formValues.pattern === Pattern.Weekly) {
        return Boolean(
          formValues.MONDAY ||
            formValues.TUESDAY ||
            formValues.WEDNESDAY ||
            formValues.THURSDAY ||
            formValues.FRIDAY ||
            formValues.SATURDAY ||
            formValues.SUNDAY
        );
      }
      return true;
    });
};

export const patchAdjustmentFormFields = (
  patches: Patch[],
  edit = false
): InputFieldProps[] => {
  const engineerMobilisationFields: InputFieldProps[] = [];
  if (!edit) {
    engineerMobilisationFields.push({
      id: 'differentStartLocation',
      label: 'Different start location?',
      name: 'differentStartLocation',
      inputType: 'radio',
      qaId: 'differentStartLocation',
      options: [
        {
          label: 'Yes',
          value: 'true'
        },
        {
          label: 'No',
          value: 'false'
        }
      ]
    });
  }
  engineerMobilisationFields.push({
    id: 'startPostcode',
    label: 'Start postcode',
    name: 'startPostcode',
    inputType: 'textfield',
    qaId: 'startPostcode',
    conditional: {
      when: 'differentStartLocation',
      is: 'true'
    }
  });

  const { descriptiveFields, timePeriodFields } = getSharedAdjustmentFormConfig(
    'patch'
  );

  const formFields: InputFieldProps[] = [
    ...descriptiveFields,
    {
      id: `patchPattern`,
      label: 'Recurring',
      name: 'pattern',
      qaId: `patchAddRecurring`,
      inputType: 'select',
      options: recurringSelectOptions()
    },
    ...weekdayCheckboxes,
    ...timePeriodFields,
    {
      id: 'patch',
      label: 'Patch',
      name: 'patch',
      qaId: 'addPatchAdjustment',
      inputType: 'select',
      options: patches && patches.map(patchDataToSelect)
    }
  ];

  return formFields.concat(engineerMobilisationFields);
};
