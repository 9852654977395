import { DATE_WITH_MONTH_WORD_FORMAT } from 'constants/dates';
import { DateTime } from 'luxon';
import {
  AppliedFilter,
  FilterFieldType,
  Filter
} from 'components/FilterSearch/FilterSearch';
import { dateRangeStringToLuxon } from 'components/FilterSearch/FieldInputs/DateRangeField';
import { QueryParams } from 'store/utils';

export type ErrorStatus = { status: { message: string }; code?: number } | null;

// Will match CSVs both with and without spaces
export const csvRegEx = /^[a-z0-9]+(?:, ?[a-z0-9]+)*$/i;

export const validateCsvString = (value: string) => {
  if (!value) {
    return 'Required';
  }
  const match = csvRegEx.test(value);

  if (!match) {
    return 'Invalid CSV format';
  }
  return undefined;
};

export const arrayToCsvString = (value: string[]) => {
  return value ? value.map((el: string) => el && el.trim()).join(', ') : value;
};

export const mergeErrors = (errors: ErrorStatus[]): ErrorStatus => {
  const errorMessages = errors.reduce((messages: string[], error) => {
    if (error?.status?.message) {
      messages.push(error.status.message);
    }
    return messages;
  }, []);

  return errorMessages.length > 0
    ? { status: { message: errorMessages.join('. ') } }
    : null;
};

export const capitalise = (word: string) =>
  // This regex will also handle non-ASCII characters (i.e. ñ, ç, å, etc.)
  word.replace(/(^|\s)\S/g, (letter: string) => letter.toUpperCase());

export const capitaliseFirstLetter = (word: string) => {
  if (word.length) {
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  return word;
};

export const camelToTitleCase = (camelCase: string) =>
  camelCase
    // Match each capital of the `camelCase` string
    .replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1')
    // Upper case the first letter of the string
    .replace(/^./, letter => capitalise(letter));

export function camelToSentenceCase(text: string) {
  // Adding space between strings
  const result = text.replace(/([A-Z])/g, ' $1').toLowerCase();

  // Converting first character to uppercase and join it to the modified sentence
  return result[0].toUpperCase().concat(result.slice(1));
}

/**
 * Utility method for consistent formatting of date strings.
 * @param {string} date - The date to be formatted. The `date` provided is expected to be an ISO 8601 compliant date.
 * @example
 * // Returns '01 Jan 70'
 * dateFormatter('1970-01-01')
 * // Returns '09 May 22'
 * dateFormatter('2022-05-09T10:32:41.132Z')
 * // Returns '08 Apr 16'
 * dateFormatter('2016-04-08T19:12:19+0000')
 * // Returns '02 Jul 21'
 * dateFormatter('2021-W26-6')
 */
export const dateFormatter = (date: string) =>
  DateTime.fromISO(date).toFormat(DATE_WITH_MONTH_WORD_FORMAT);

export const onlyUnique = (a: any[]) => Array.from(new Set(a));

export const throttle = (fn: Function, wait: number) => {
  let isCalled = false;

  return (...args: any[]) => {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(() => {
        isCalled = false;
      }, wait);
    }
  };
};

export const memoize = (fn: Function) => {
  const cache: Record<string, any> = {};

  return (...args: any[]) => {
    const allArgs = args.reduce(
      (accumulator: any, currentValue: any, index: number) => {
        accumulator[index] = currentValue;

        return accumulator;
      },
      {}
    );

    const argsKey: string = JSON.stringify(allArgs);

    if (argsKey in cache) {
      // Fetching from cache
      return cache[argsKey];
    }

    // Calculating result
    const result = fn(...args);
    cache[argsKey] = result;

    return result;
  };
};

export function getNumberSuffix(num: number) {
  const [th, rd, nd, st] = ['th', 'rd', 'nd', 'st'];

  if (num === 11 || num === 12 || num === 13) {
    return th;
  }

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case '1':
      return st;
    case '2':
      return nd;
    case '3':
      return rd;
    default:
      return th;
  }
}
export const getFilterValue = (value: string, filter: Filter) => {
  if (filter.fieldType === FilterFieldType.DATE_RANGE) {
    return DateTime.fromISO(value).toISODate();
  }
  return value;
};

export const getFilterParam = ({ filter, value }: AppliedFilter) => {
  const filterValue = getFilterValue(value, filter);
  const paramValue = filter.operator === '%' ? `%${filterValue}%` : filterValue;
  const param = {} as any;
  param[filter.queryString] = {
    values: [paramValue],
    operator: filter.operator
  };
  return param;
};

const getDateFilter = (date: DateTime, queryString: string): AppliedFilter => {
  return {
    value: String(date),
    filter: {
      id: 'date',
      name: 'date',
      queryString,
      operator: '=',
      fieldType: FilterFieldType.DATE_RANGE
    }
  };
};

export const handleDateRangeFilter = (
  appliedFilters: AppliedFilter[]
): AppliedFilter[] => {
  const dateRangeFilter = appliedFilters.find(
    f => f.filter.fieldType == FilterFieldType.DATE_RANGE
  );
  if (dateRangeFilter) {
    const [startDate, endDate] = dateRangeStringToLuxon(dateRangeFilter.value);
    return [
      ...appliedFilters.filter(
        f => f.filter.fieldType != FilterFieldType.DATE_RANGE
      ),
      getDateFilter(startDate, 'startDate'),
      getDateFilter(endDate, 'endDate')
    ];
  }
  return appliedFilters;
};

export const getFilterQueryParams = (
  appliedFilters: AppliedFilter[]
): QueryParams =>
  handleDateRangeFilter(appliedFilters)
    .map(getFilterParam)
    .reduce((acc, val) => {
      return {
        ...acc,
        ...val
      };
    }, {} as QueryParams);

export const filterById = (id: string) => (f: Record<string, any>) =>
  f.id === id;

export const filterByValue = (f: Record<string, any> | null) => f?.value;

export const getChangedFields = (
  initial: { [key: string]: any },
  changed: { [key: string]: any }
) =>
  Object.fromEntries(
    Object.entries(changed).filter(
      ([key, val]) => key in initial && initial[key] !== val
    )
  );

export const redirectToEngineer = (engineerId?: string) => {
  if (engineerId) {
    window.open(`${window.location.origin}/dashboard/engineers/${engineerId}`);
  }
};

export const redirectToJob = (appointmentId: string) => {
  window.open(`${window.location.origin}/dashboard/jobs/${appointmentId}`);
};
