import { InputFieldProps } from 'components/Forms';
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'constants/validation';
import Adjustment, {
  AdjustmentType,
  DayOfWeek,
  PostcodeAdjustmentChange
} from 'models/Adjustment';
import { patchReasonOptions } from 'views/Engineers/Adjustments/AdjustmentFormConfig';
import * as Yup from 'yup';
import { getAdjustmentChange, getAdjustmentForDay } from 'utils/adjustments';
import AdjustmentGroup from 'models/AdjustmentGroup';
import {
  TemporaryAdjustmentConstants,
  atLeastOneFilledSchema
} from 'views/Engineers/utils';
import {
  adjustmentCommonFieldsInitialValues,
  adjustmentCommonFieldsValidationSchema,
  adjustmentWeekdayKeys,
  getAdjustmentCommonFields
} from './adjustmentFormCommonConfig';

export const getDayFieldName = (day: string) => `patchChange${day}`;
export enum PostcodeChangeFieldName {
  AddStartLocation = 'differentStartLocation',
  Postcode = 'startPostcode'
}

// Field Definitions

const getPatchFieldForWeekday = (day: string): InputFieldProps => {
  const fieldName = getDayFieldName(day);
  return {
    id: fieldName,
    name: fieldName,
    qaId: fieldName,
    disablePlaceholder: true,
    inputType: 'select',
    options: [] // set to the list of patches in the form component
  };
};

export const patchFormCommonFields = getAdjustmentCommonFields(
  patchReasonOptions()
);

export const patchFormWeekdayFields = Object.fromEntries(
  adjustmentWeekdayKeys.map(day => [day, getPatchFieldForWeekday(day)])
);

export const patchFormStartLocationFields: InputFieldProps[] = [
  {
    id: PostcodeChangeFieldName.AddStartLocation,
    name: PostcodeChangeFieldName.AddStartLocation,
    qaId: PostcodeChangeFieldName.AddStartLocation,
    label: 'Different start location?',
    inputType: 'radio',
    options: [
      { value: 'true', label: 'Yes', key: 'true' },
      { value: 'false', label: 'No', key: 'false' }
    ]
  },
  {
    id: PostcodeChangeFieldName.Postcode,
    name: PostcodeChangeFieldName.Postcode,
    qaId: PostcodeChangeFieldName.Postcode,
    label: 'Start postcode',
    inputType: 'textfield'
  }
];

export const patchFormValidationSchema = Yup.object()
  .shape({
    ...adjustmentCommonFieldsValidationSchema,
    [PostcodeChangeFieldName.AddStartLocation]: Yup.string().required(
      'Required'
    ),
    [PostcodeChangeFieldName.Postcode]: Yup.string().when(
      PostcodeChangeFieldName.AddStartLocation,
      {
        is: 'true',
        then: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
      }
    )
  })
  .test('atLeastOneFilled', function validOneShiftPattern(formValues) {
    return atLeastOneFilledSchema(
      formValues,
      getDayFieldName,
      this.createError
    );
  });

function getPatchChangeFormValues(
  adjustments: Adjustment[]
): Record<string, string | undefined> {
  const patchChangeFormValuesEntries = adjustmentWeekdayKeys.map(day => {
    const matchedAdjustment = getAdjustmentForDay(
      day.toUpperCase() as DayOfWeek,
      adjustments
    );

    const inputFieldNameKey = getDayFieldName(day);

    if (matchedAdjustment) {
      const change = getAdjustmentChange(
        matchedAdjustment,
        AdjustmentType.Patch
      )?.change;

      return [inputFieldNameKey, change];
    }

    return [inputFieldNameKey, TemporaryAdjustmentConstants.Empty];
  });

  return Object.fromEntries(patchChangeFormValuesEntries);
}

// Initial values
export const patchFormWeekdaysInitialValues = (
  adjustmentGroup: AdjustmentGroup | undefined
) => {
  const defaultInitialValues = {
    ...Object.fromEntries(
      adjustmentWeekdayKeys.map(day => [
        getDayFieldName(day),
        TemporaryAdjustmentConstants.Empty
      ])
    ),
    [PostcodeChangeFieldName.AddStartLocation]: 'false'
  };

  const adjustments = adjustmentGroup?.adjustments;

  if (!adjustments || adjustments.length === 0) {
    return defaultInitialValues;
  }

  const postcodeChange = adjustments.reduce<
    PostcodeAdjustmentChange | undefined
  >(
    (prev, curr) => prev || getAdjustmentChange(curr, AdjustmentType.Postcode),
    undefined
  );

  return {
    ...defaultInitialValues,
    ...getPatchChangeFormValues(adjustments),
    ...(postcodeChange
      ? {
          [PostcodeChangeFieldName.AddStartLocation]: 'true',
          [PostcodeChangeFieldName.Postcode]: postcodeChange.change
        }
      : {})
  };
};

export const patchFormInitialValues = (adjustmentGroup?: AdjustmentGroup) => {
  const patchDaysInitialValues = patchFormWeekdaysInitialValues(
    adjustmentGroup
  );

  return {
    ...adjustmentCommonFieldsInitialValues(adjustmentGroup?.adjustments[0]),
    ...patchDaysInitialValues
  };
};
