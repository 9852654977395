import { InputFieldProps, patchDataToSelect } from 'components/Forms';
import {
  shiftAdjustmenEndTime,
  shiftAdjustmentStartTime
} from 'constants/schedule';
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'constants/validation';
import { DateTime, Duration } from 'luxon';
import Adjustment, { AdjustmentType } from 'models/Adjustment';
import Patch from 'models/Patches';
import ShiftPattern, { Weekdays } from 'models/ShiftPattern';
import { getAdjustmentChange } from 'utils/adjustments';
import { compareTime } from 'utils/dates';
import { scheduleReasonSelectOptions } from 'views/Engineers/Adjustments/AdjustmentFormConfig';
import {
  getEfficiencyOptions,
  atLeastOneFilledSchema,
  TemporaryAdjustmentConstants
} from 'views/Engineers/utils';
import * as Yup from 'yup';
import {
  adjustmentCommonFieldsInitialValues,
  adjustmentCommonFieldsValidationSchema,
  adjustmentWeekdayKeys,
  getAdjustmentCommonFields
} from './adjustmentFormCommonConfig';

export const getDayStartFieldName = (day: string) => `shiftChangeStart${day}`;
export const getDayEndFieldName = (day: string) => `shiftChangeEnd${day}`;

export enum ShiftAdjustmentFormFieldName {
  AddPatch = 'addPatchChange',
  PatchChange = 'patchChange',
  AddEfficiency = 'addEfficiencyChange',
  EfficiencyChange = 'efficiencyChange'
}

// Field definitions

export const shiftFormCommonFields = getAdjustmentCommonFields(
  scheduleReasonSelectOptions()
);
const notWorkingOption = {
  label: 'Not working',
  value: TemporaryAdjustmentConstants.NotWorking,
  key: 'not-working'
};
const emptyOption = {
  label: '',
  value: TemporaryAdjustmentConstants.Empty,
  key: TemporaryAdjustmentConstants.Empty
};
const getShiftWeekdayTimeFieldOptions = () => {
  const dtEnd = DateTime.fromFormat(shiftAdjustmenEndTime, 'HH:mm');
  const durationInterval = Duration.fromISOTime('00:15');

  const options = [];

  let currentTime = DateTime.fromFormat(shiftAdjustmentStartTime, 'HH:mm');

  while (currentTime <= dtEnd) {
    const formattedTime = currentTime.toFormat('HH:mm');

    options.push({
      label: formattedTime,
      value: formattedTime,
      key: formattedTime
    });

    currentTime = currentTime.plus(durationInterval);
  }

  return [...options];
};

export const shiftFormWeekdayFields = (): {
  [key: string]: InputFieldProps[];
} => {
  return Object.fromEntries(
    adjustmentWeekdayKeys.map(day => [
      day,
      [
        {
          name: getDayStartFieldName(day),
          id: getDayStartFieldName(day),
          qaId: getDayStartFieldName(day),
          inputType: 'select',
          disablePlaceholder: true,
          options: [
            emptyOption,
            notWorkingOption,
            ...getShiftWeekdayTimeFieldOptions()
          ]
        },
        {
          name: getDayEndFieldName(day),
          id: getDayEndFieldName(day),
          qaId: getDayEndFieldName(day),
          inputType: 'select',
          disablePlaceholder: true,
          options: [emptyOption, ...getShiftWeekdayTimeFieldOptions()],
          conditional: {
            when: getDayStartFieldName(day),
            matchesPredicate: (value: string) => {
              return (
                value !== TemporaryAdjustmentConstants.NotWorking &&
                value !== TemporaryAdjustmentConstants.Empty
              );
            }
          }
        }
      ]
    ])
  );
};

export function shiftFormPatchChangeFields(patches: Patch[] | null) {
  const patchAdjustmentFields: InputFieldProps[] = [
    {
      id: ShiftAdjustmentFormFieldName.AddPatch,
      name: ShiftAdjustmentFormFieldName.AddPatch,
      qaId: ShiftAdjustmentFormFieldName.AddPatch,
      label: 'Would you like to add a patch adjustment for the duration?',
      inputType: 'radio',
      options: [
        { value: 'true', label: 'Yes', key: 'true' },
        { value: 'false', label: 'No', key: 'false' }
      ]
    },
    {
      id: ShiftAdjustmentFormFieldName.PatchChange,
      name: ShiftAdjustmentFormFieldName.PatchChange,
      qaId: ShiftAdjustmentFormFieldName.PatchChange,
      label: 'New patch',
      inputType: 'select',
      options: patches?.map(patchDataToSelect)
    }
  ];

  return patchAdjustmentFields;
}

export const shiftFormEfficiencyChangeFields: InputFieldProps[] = [
  {
    id: ShiftAdjustmentFormFieldName.AddEfficiency,
    name: ShiftAdjustmentFormFieldName.AddEfficiency,
    qaId: ShiftAdjustmentFormFieldName.AddEfficiency,
    label: 'Would you like to add an efficiency adjustment for the duration?',
    inputType: 'radio',
    options: [
      { value: 'true', label: 'Yes', key: 'true' },
      { value: 'false', label: 'No', key: 'false' }
    ]
  },
  {
    id: ShiftAdjustmentFormFieldName.EfficiencyChange,
    name: ShiftAdjustmentFormFieldName.EfficiencyChange,
    qaId: ShiftAdjustmentFormFieldName.EfficiencyChange,
    label: 'New efficiency',
    inputType: 'select',
    options: getEfficiencyOptions()
  }
];

// Validation schema

export const shiftFormDaysValidationSchemaObject = adjustmentWeekdayKeys
  .map(day => ({
    [getDayEndFieldName(day)]: Yup.string()
      .when(getDayStartFieldName(day), (shiftChangeStart: string) => {
        if (
          shiftChangeStart === TemporaryAdjustmentConstants.NotWorking ||
          shiftChangeStart === TemporaryAdjustmentConstants.Empty
        ) {
          return Yup.string().notRequired();
        }
        return Yup.string().notOneOf(
          [
            'End time',
            undefined,
            TemporaryAdjustmentConstants.NotWorking,
            TemporaryAdjustmentConstants.Empty
          ],
          REQUIRED_FIELD_ERROR_MESSAGE
        );
      })
      .when(getDayStartFieldName(day), (shiftChangeEnd: string, schema: any) =>
        schema.test({
          test: (shiftChangeStart: any) => {
            if (!shiftChangeStart || shiftChangeStart === '') {
              return true;
            }
            return !compareTime(shiftChangeStart, shiftChangeEnd);
          },
          message: 'End time must be after start time'
        })
      )
  }))
  .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const shiftFormFieldsValidationSchema = Yup.object()
  .shape({
    ...adjustmentCommonFieldsValidationSchema,
    ...shiftFormDaysValidationSchemaObject,
    [ShiftAdjustmentFormFieldName.AddPatch]: Yup.boolean(),
    [ShiftAdjustmentFormFieldName.PatchChange]: Yup.string().when(
      ShiftAdjustmentFormFieldName.AddPatch,
      (addPatchAdjustment: boolean) => {
        if (addPatchAdjustment === true) {
          return Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE);
        }
        return Yup.string().notRequired();
      }
    ),
    [ShiftAdjustmentFormFieldName.AddEfficiency]: Yup.boolean(),
    [ShiftAdjustmentFormFieldName.EfficiencyChange]: Yup.string().when(
      ShiftAdjustmentFormFieldName.AddEfficiency,
      (addEfficiencyAdjustment: boolean) => {
        if (addEfficiencyAdjustment === true) {
          return Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE);
        }
        return Yup.string().notRequired();
      }
    ),
    ...shiftFormDaysValidationSchemaObject
  })
  .test('atLeastOneFilled', function validOneShiftPattern(formValues) {
    return atLeastOneFilledSchema(
      formValues,
      getDayStartFieldName,
      this.createError
    );
  });

// Initial values

// TODO: https://ovotech.atlassian.net/browse/FTSC-1391
const getShiftPatternDay = (day: string, shiftPattern?: ShiftPattern) =>
  shiftPattern?.[day.toLowerCase() as Weekdays] ||
  shiftPattern?.[day.toUpperCase() as Weekdays];

export const shiftFormDaysInitialValues = (
  adjustmentShiftPattern?: ShiftPattern
) =>
  adjustmentWeekdayKeys
    .map(day => {
      const shiftPatternDay = getShiftPatternDay(day, adjustmentShiftPattern);
      if (shiftPatternDay === null) {
        return {
          [getDayStartFieldName(day)]: TemporaryAdjustmentConstants.NotWorking,
          [getDayEndFieldName(day)]: TemporaryAdjustmentConstants.NotWorking
        };
      }
      if (shiftPatternDay) {
        return {
          [getDayStartFieldName(day)]: shiftPatternDay.startTime,
          [getDayEndFieldName(day)]: shiftPatternDay.endTime
        };
      }
      return {
        [getDayStartFieldName(day)]: TemporaryAdjustmentConstants.Empty,
        [getDayEndFieldName(day)]: TemporaryAdjustmentConstants.Empty
      };
    })
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

export const shiftFormInitialValues = (adjustment?: Adjustment) => {
  const adjustmentShiftPattern = adjustment
    ? getAdjustmentChange(adjustment, AdjustmentType.Shift)
    : null;

  const adjustmentPatch = adjustment
    ? getAdjustmentChange(adjustment, AdjustmentType.Patch)
    : null;

  const adjustmentEfficiency = adjustment
    ? getAdjustmentChange(adjustment, AdjustmentType.Efficiency)
    : null;

  const shiftPatternInitialValues = shiftFormDaysInitialValues(
    adjustmentShiftPattern?.change
  );

  return {
    ...adjustmentCommonFieldsInitialValues(adjustment),
    [ShiftAdjustmentFormFieldName.AddPatch]:
      adjustmentPatch == null ? 'false' : 'true',
    [ShiftAdjustmentFormFieldName.AddEfficiency]:
      adjustmentEfficiency == null ? 'false' : 'true',
    [ShiftAdjustmentFormFieldName.PatchChange]: adjustmentPatch?.change || '',
    [ShiftAdjustmentFormFieldName.EfficiencyChange]:
      adjustmentEfficiency?.change || '',
    ...shiftPatternInitialValues
  };
};
